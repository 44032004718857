import React, { FC, useCallback, useEffect, useMemo } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
import { Caption, formatDate } from "@likemagic-tech/sv-magic-library";
import { ChatInput } from "./chat-input";
import { ConversationDetails } from "../domain/conversation";
import { transformCommunicationPlatformV2 } from "../../graphql/transform/transform-utils";
import * as Types from "../../graphql-messaging/generated/graphql";
import { ConversationStatus } from "../../graphql-messaging/generated/graphql";
import { useEmployee } from "../../hooks/use-employee";
import { ChatInputDisabledReason, isAiBot } from "../domain/conversation-constants";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { api as setConversationAsReadApi } from "../../graphql-messaging/mutations/SetConversationAsRead.generated";
import { useDispatch } from "../../store";
import { useConversationSelectId } from "../use-conversation-select-id";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { useProperty } from "../../hooks/use-property";
import { getI18nSelectedLanguage } from "../../utils/language";
import { ChatMessages } from "./chat-messages";
import { makeStyles } from "tss-react/mui";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { grey } from "@mui/material/colors";
import { ChatPreviewHeader } from "./chat-preview-header";

interface ChatProps {
  conversation: ConversationDetails;
  propertyIds: string[];
}
const useStyles = makeStyles()((theme) => ({
  content: {
    flexGrow: "inherit",
    background: theme.palette.background.default,
    padding: theme.spacing(0, 1, 0, 1),
    zIndex: 1
  },
  contentDisabled: {
    flexGrow: "inherit",
    background: theme.palette.background.default,
    padding: theme.spacing(0, 1, 0, 1),
    zIndex: 1,
    color: grey["300"]
  },
  root: {
    "&:before": {
      display: "none"
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      height: 1,
      background: theme.palette.grey.A400,
      top: theme.spacing(3)
    }
  },
  previewShadow: {
    "&::after": {
      content: '" "',
      position: "absolute",
      left: 0,
      right: 0,
      height: 20,
      zIndex: 2,
      marginTop: 10,
      background: `linear-gradient(to bottom, ${theme.palette.background.default} 20%, transparent 99%)`
    }
  },
  expandIconWrapper: {
    background: "red"
  }
}));

export const Chat: FC<ChatProps> = ({ conversation }) => {
  const { conversationId, status, assignee } = conversation;
  const dispatch = useDispatch();
  const { t } = useTranslationWrapper();
  const platformOfLastMessage = useMemo(() => conversation.replyToPlatform, [conversation]);
  const language = getI18nSelectedLanguage();
  const { classes } = useStyles();
  const { openDrawer } = useConversationSelectId();

  const { getProperty } = useProperty();

  const propertyId = useMemo(
    () =>
      conversation.pmsPropertyId === "ACCOUNT"
        ? ""
        : getProperty(conversation.pmsPropertyId)?.propertyId ?? "",
    [getProperty, conversation.pmsPropertyId]
  );

  const { currentEmployee } = useEmployee([propertyId]);

  const isMobile = useIsMobile();

  useEffect(() => {
    if (
      conversation.conversationId &&
      currentEmployee?.actorId &&
      currentEmployee?.actorId.toString() === conversation.assignee?.actorId
    ) {
      dispatch(
        setConversationAsReadApi.endpoints.SetConversationAsRead.initiate({
          conversationId: conversation.conversationId
        })
      );
    }
  }, [
    dispatch,
    conversation.conversationId,
    currentEmployee?.actorId,
    conversation.assignee?.actorId
  ]);

  const disabledReason = useMemo(() => {
    if (status === ConversationStatus.Resolved) {
      return ChatInputDisabledReason.CONVERSATION_RESOLVED;
    } else if (isAiBot(assignee)) {
      return ChatInputDisabledReason.CONVERSATION_IS_ASSIGNED_TO_AI_BOT;
    } else if (currentEmployee?.actorId?.toString() !== assignee?.actorId && !!assignee?.actorId) {
      return ChatInputDisabledReason.CONVERSATION_IS_ASSIGNED_TO_OTHER_PERSON;
    } else {
      return ChatInputDisabledReason.NONE;
    }
  }, [status, currentEmployee?.actorId, assignee]);

  const sortedLastConversations = useMemo(() => {
    return conversation?.guestIdentity?.lastConversations
      ? [...conversation?.guestIdentity?.lastConversations].reverse()
      : [conversation];
  }, [conversation]);
  const isSelectedConversation = useCallback((t: string) => conversationId === t, [conversationId]);

  const formatTitle = useCallback(
    (lastConversationItem: {
      status: Types.ConversationStatus;
      pmsPropertyId: string;
      assignee?: {
        displayName?: string | null;
      } | null;
      lastReceivedMessage?: any;
    }) => {
      const mobile = `${t(`labels__conversation_status_${lastConversationItem.status}`)} ${t(
        "labels__conversation"
      )} ${formatDate(lastConversationItem?.lastReceivedMessage, language)}`;

      const desktop = ` | ${t("labels__property")}: ${getProperty(
        lastConversationItem.pmsPropertyId
      )?.details?.name}`.concat(
        lastConversationItem.assignee?.displayName
          ? ` | ${t("labels__assignee")}:${lastConversationItem.assignee?.displayName}`
          : ""
      );
      return mobile.concat(isMobile ? "" : desktop);
    },
    [t, language, isMobile, getProperty]
  );

  return (
    <Box
      sx={{
        pt: isMobile ? 2.5 : 3,
        display: "flex",
        flexDirection: "column",
        height: "100%"
      }}
    >
      {isMobile && (
        <Box px={2} pb={2} className={classes.previewShadow}>
          <ChatPreviewHeader conversation={conversation} />
        </Box>
      )}
      <Box
        sx={{
          flexGrow: 1,
          pr: !isMobile ? 3 : 1,
          pl: !isMobile ? 3 : 1,
          overflowY: "scroll"
        }}
      >
        {sortedLastConversations?.map((lastConversationItem) => (
          <Accordion
            key={`accordion-of-${lastConversationItem.conversationId}`}
            sx={{
              background: "transparent",
              boxShadow: "none"
            }}
            classes={{
              root: classes.root
            }}
            disableGutters
            expanded={isSelectedConversation(lastConversationItem.conversationId)}
            onChange={() => {
              if (isSelectedConversation(lastConversationItem.conversationId)) {
                //TODO what to do ?
              } else {
                openDrawer(lastConversationItem.conversationId);
              }
            }}
          >
            <AccordionSummary
              aria-controls={`${lastConversationItem.conversationId}-content`}
              id={`${lastConversationItem.conversationId}-header`}
              classes={{
                content: isSelectedConversation(lastConversationItem.conversationId)
                  ? classes.contentDisabled
                  : classes.content
              }}
              sx={{
                color: grey["800"],
                justifyContent: "end",
                p: 0
              }}
            >
              <Caption mt={0.5} component="span" textAlign="center" color={grey["800"]}>
                {formatTitle(lastConversationItem)}
              </Caption>
              {!isSelectedConversation(lastConversationItem.conversationId) ? (
                <ExpandMoreIcon />
              ) : (
                <ExpandLessIcon />
              )}
            </AccordionSummary>

            {isSelectedConversation(lastConversationItem.conversationId) && (
              <AccordionDetails sx={{ p: 0 }}>
                <Box
                  sx={{
                    pb: 2
                  }}
                >
                  <ChatMessages
                    conversationId={lastConversationItem.conversationId}
                    selectedThread={isSelectedConversation(lastConversationItem.conversationId)}
                  />
                </Box>
              </AccordionDetails>
            )}
          </Accordion>
        ))}
      </Box>
      <Box
        sx={{
          pb: !isMobile ? 3 : 0,
          pr: !isMobile ? 3 : 0,
          pl: !isMobile ? 3 : 0,
          pt: !isMobile ? 1 : 0,
          width: "100%"
        }}
      >
        <ChatInput
          version={conversation.messageBirdVersion}
          conversationId={conversationId}
          communicationChannel={transformCommunicationPlatformV2(platformOfLastMessage)}
          disabledReason={disabledReason}
          assignee={assignee}
          propertyId={propertyId}
        />
      </Box>
    </Box>
  );
};
